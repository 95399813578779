import service from "./request";
//接口前缀
const BUS_PREFIX = "/bus";
/* -----------------  公交2.0 ---------------------- */

//公交2.0新增线路路队长--用户下拉列表
const queryUserByCompanyIds = data => {
  return service({
    url: `/base/user/queryLineUserByCompanyIds`,
    method: "get",
    data
  });
};
//设置运营方案分组、车辆、驾驶员
const setGroupAPI = data => {
  return service({
    url: `/bus/operate/detail/setGroup`,
    method: "post",
    data
  });
};
//运营方案执行设置
const addSchedulingV2API = data => {
  return service({
    url: `/bus/schedulingManage/addSchedulingV2`,
    method: "post",
    data
  });
};
//发车时刻表-新表
const querySchedulingListV2API = data => {
  return service({
    url: `/bus/schedulingManage/startTimeTable/v2`,
    method: "post",
    data
  });
};
//发车时刻表-班次调整驾驶员
const schedulingChangDriverAPI = data => {
  return service({
    url: `/bus/schedulingManage/banci/changDriver`,
    method: "post",
    data
  });
};
//发车时刻表-班次调整驾驶员
const schedulingChangVehicleAPI = data => {
  return service({
    url: `/bus/schedulingManage/banci/changVehicle`,
    method: "post",
    data
  });
};
//电子地图--车辆实时位置
const queryBusPositionsAPI = data => {
  return service({
    url: `/bus/electronicMapUpgrade/queryBusPositions`,
    method: "post",
    data
  });
};
//方案到期提醒
const querylistMyAPI = data => {
  return service({
    url: `/bus/message/listMy`,
    method: "get",
    data
  });
};
//实时调度保存已选线路
const saveFollowLineAPI = data => {
  return service({
    url: `/bus/dispatchPlatformUpgrade/saveFollowLine`,
    method: "post",
    data
  });
};
//实时调度获取已选线路
const queryFollowLineAPI = data => {
  return service({
    url: `/bus/dispatchPlatformUpgrade/queryFollowLine`,
    method: "get",
    data
  });
};
//实时调度-车辆营运统计
const queryVehicleStatisticsAPI = data => {
  return service({
    url: `/bus/dispatchPlatformUpgrade/queryVehicleStatistics`,
    method: "get",
    data
  });
};
//实时调度-车次完成情况
const queryLineTripsStatisticsAPI = data => {
  return service({
    url: `/bus/dispatchPlatformUpgrade/queryLineTripsStatistics`,
    method: "get",
    data
  });
};
//实时调度-准点率统计
const queryPlanTimelyAPI = data => {
  return service({
    url: `/bus/dispatchPlatformUpgrade/queryPlanTimely`,
    method: "get",
    data
  });
};
//实时调度-主副场待发班信息
const queryDispatchPlanAPI = data => {
  return service({
    url: `/bus/dispatchPlatformUpgrade/queryDispatchPlan`,
    method: "get",
    data
  });
};
//实时调度-已完成车次到离站详情
const queryRecordArriveList = data => {
  return service({
    url: `/bus/dispatchPlatformUpgrade/queryRecordArriveList`,
    method: "get",
    data
  });
};
//拖拽修改时间
const batchUpdateWorkTriptAPI = data => {
  return service({
    url: `/bus/dispatchPlatformUpgrade/batchUpdateWorkTrip`,
    method: "post",
    data
  });
};
//修改时间车次变更
const updateWorkTripAPI = data => {
  return service({
    url: `/bus/dispatchPlatformUpgrade/updateWorkTrip`,
    method: "post",
    data
  });
};
//实时调度-车辆位置信息
const realQueryBusPositionsAPI = data => {
  return service({
    url: `/bus/dispatchPlatformUpgrade/queryBusPositions`,
    method: "get",
    data
  });
};
//发车时刻表-驾驶员可选择列表
const queryDriverByCompanyIdAndNameAPI = data => {
  return service({
    url: `/base/driver/queryDriverByCompanyIdAndName`,
    method: "get",
    data
  });
};
//实时调度-车辆卡片信息
const queryBusCardAPI = data => {
  return service({
    url: `/bus/dispatchPlatformUpgrade/queryBusCard`,
    method: "get",
    data
  });
};

//未完成列表-实时调度2.0
const getNotStartDispatchCheCiAPI = data => {
  return service({
    url: `/bus/dispatchPlatformUpgrade/getNotStartDispatchCheCi`,
    method: "post",
    data
  });
};
//实时调度-车辆请求
const queryNewEventsAPI = data => {
  return service({
    url: `/bus/dispatchPlatformUpgrade/queryNewEvents`,
    method: "post",
    data
  });
};
//今日线路离线车辆列表
const queryLineTodayOfflinePositionsAPI = data => {
  return service({
    url: `/bus/dispatchPlatformUpgrade/queryLineTodayOfflinePositions`,
    method: "get",
    data
  });
};

//车次到离站数据详情列表
const queryCompleteCheCiArriveDetailAPI = data => {
  return service({
    url: `/bus/dispatchPlatformUpgrade/getCompleteCheCiArriveDetail`,
    method: "get",
    data
  });
};
//车次里程信息
const queryCheCiRunMileAPI = data => {
  return service({
    url: `/bus/dispatchPlatformUpgrade/getCheCiRunMile`,
    method: "get",
    data
  });
};
//排班确认信息
const queryMsgListAPI = data => {
  return service({
    url: `/bus/confirmScheduleMsg/getMsgList`,
    method: "get",
    data
  });
};
//线路当前执行方案
const queryCurrentOperateAPI = data => {
  return service({
    url: `/bus/operate/getCurrentOperate`,
    method: "get",
    data
  });
};

export {
  queryUserByCompanyIds,
  setGroupAPI,
  addSchedulingV2API,
  querySchedulingListV2API,
  schedulingChangDriverAPI,
  schedulingChangVehicleAPI,
  queryBusPositionsAPI,
  querylistMyAPI,
  saveFollowLineAPI,
  queryFollowLineAPI,
  queryVehicleStatisticsAPI,
  queryLineTripsStatisticsAPI,
  queryPlanTimelyAPI,
  queryDispatchPlanAPI,
  queryRecordArriveList,
  batchUpdateWorkTriptAPI,
  updateWorkTripAPI,
  realQueryBusPositionsAPI,
  queryDriverByCompanyIdAndNameAPI,
  queryBusCardAPI,
  getNotStartDispatchCheCiAPI,
  queryNewEventsAPI,
  queryLineTodayOfflinePositionsAPI,
  queryCompleteCheCiArriveDetailAPI,
  queryCheCiRunMileAPI,
  queryMsgListAPI,
  queryCurrentOperateAPI
};
/* -----------------  公交2.0 ---------------------- */

/* -----------------  统计报表模块 ---------------------- */
//行驶统计分页查询
const getDriverRecordList = data => {
  return service({
    url: `${BUS_PREFIX}/drivingRecord/queryPageByQuery`,
    method: "post",
    data
  });
};
//行驶统计分页查询-2022.12.29新版只供查询
const getDriverRecordListNew = data => {
  return service({
    url: `${BUS_PREFIX}/drivingRecord/queryPageByQueryNew`,
    method: "post",
    data
  });
};
//补录行程
const addDriverRecord = data => {
  return service({
    url: `${BUS_PREFIX}/drivingRecord/supplement`,
    method: "post",
    data
  });
};
//确认行程
const confirmDrivingRecord = data => {
  return service({
    url: `${BUS_PREFIX}/drivingRecord/confirmDrivingRecord`,
    method: "post",
    data
  });
};
//生成结算单
const createSettlements = data => {
  return service({
    url: `${BUS_PREFIX}/drivingRecord/settlements`,
    method: "post",
    data
  });
};

export {
  getDriverRecordList, //行驶统计分页查询
  getDriverRecordListNew, //行驶统计分页查询-新
  addDriverRecord, //补录行程
  confirmDrivingRecord, //确认行程
  createSettlements //生成结算单
};

/* -----------------  统计报表模块 ---------------------- */

/* -----------------  车场管理模块 ---------------------- */
// 获取车场列表
const getBusParkList = data => {
  return service({
    url: `${BUS_PREFIX}/park/queryPageByQuery`,
    method: "post",
    data
  });
};

// 禁用|启用 车场
const disableBusPark = data => {
  return service({
    url: `${BUS_PREFIX}/park/disableOrEnable`,
    method: "put",
    data
  });
};

// 新增车场
const insertBusPark = data => {
  return service({
    url: `${BUS_PREFIX}/park/add`,
    method: "post",
    data
  });
};

// 修改车场信息
const updateBusPark = data => {
  return service({
    url: `${BUS_PREFIX}/park/updateById`,
    method: "put",
    data
  });
};

// 根据id获取车场
const queryBusParkById = data => {
  return service({
    url: `${BUS_PREFIX}/park/queryById`,
    method: "get",
    data
  });
};

export {
  getBusParkList,
  disableBusPark,
  insertBusPark,
  updateBusPark,
  queryBusParkById
};
/* -----------------  车场管理模块 ---------------------- */

/* -----------------  站点管理模块 ---------------------- */
// 获取站点列表
const getBusStationList = data => {
  return service({
    url: `${BUS_PREFIX}/station/queryPageByQuery`,
    method: "post",
    data
  });
};

// 禁用|启用 站点
const disableBusStation = data => {
  return service({
    url: `${BUS_PREFIX}/station/disableOrEnable`,
    method: "put",
    data
  });
};

// 新增站点
const insertBusStation = data => {
  return service({
    url: `${BUS_PREFIX}/station/add`,
    method: "post",
    data
  });
};

// 修改站点信息
const updateBusStation = data => {
  return service({
    url: `${BUS_PREFIX}/station/updateById`,
    method: "put",
    data
  });
};

// 根据id获取站点
const queryBusStationById = data => {
  return service({
    url: `${BUS_PREFIX}/station/queryById`,
    method: "get",
    data
  });
};
//站点查车
const selectHistoryStationInfo = data => {
  return service({
    url: `${BUS_PREFIX}/station/selectHistoryStationInfo`,
    method: "post",
    data
  });
};

export {
  getBusStationList,
  disableBusStation,
  insertBusStation,
  updateBusStation,
  queryBusStationById,
  selectHistoryStationInfo
};
/* -----------------  站点管理模块 ---------------------- */

/* -----------------  线路管理模块 ---------------------- */
// 获取所有线路列表（搜索用）
const queryAllBusLineList = data => {
  return service({
    url: `${BUS_PREFIX}/lineDetail/queryAll`,
    method: "post",
    data
  });
};

// 获取线路列表
const getBusLineList = data => {
  return service({
    url: `${BUS_PREFIX}/lineDetail/queryPageByQuery`,
    method: "post",
    data
  });
};

// 禁用|启用 线路
const disableBusLine = data => {
  return service({
    url: `${BUS_PREFIX}/lineDetail/disableOrEnable`,
    method: "post",
    data
  });
};

// 新增线路||修改线路信息
const saveBusLine = data => {
  return service({
    url: `${BUS_PREFIX}/lineDetail/save`,
    method: "post",
    data
  });
};

// 根据id获取线路
const queryBusLineById = data => {
  return service({
    url: `${BUS_PREFIX}/lineDetail/queryByLineId`,
    method: "get",
    data
  });
};

export {
  queryAllBusLineList,
  getBusLineList,
  disableBusLine,
  saveBusLine,
  queryBusLineById
};
/* -----------------  线路管理模块 ---------------------- */
/* -----------------  运营参数模块 ---------------------- */
// 获取运营参数列表
const getListPlanList = data => {
  return service({
    url: `${BUS_PREFIX}/operate/listPlan`,
    method: "post",
    data
  });
};

// 删除 运营参数
const deleteListPlan = data => {
  return service({
    url: `${BUS_PREFIX}/operate/deletePlan/${data}`,
    method: "delete"
  });
};

// 新增运营参数||修改运营参数
const saveListPlan = data => {
  return service({
    url: `${BUS_PREFIX}/operate/addPlan`,
    method: "post",
    data
  });
};

// 根据id获取运营参数
const queryListPlanById = data => {
  return service({
    url: `${BUS_PREFIX}/operate/getById`,
    method: "get",
    data
  });
};

export { getListPlanList, deleteListPlan, saveListPlan, queryListPlanById };
/* -----------------  运营参数模块 ---------------------- */
/* -----------------  运营方案模块 ---------------------- */
// 获取运营方案列表
const getOperateList = data => {
  return service({
    url: `${BUS_PREFIX}/operate/getDetailByOperateId`,
    method: "get",
    data
  });
};

// 修改运营方案
const saveOperate = data => {
  return service({
    url: `${BUS_PREFIX}/operate/modifyScheme`,
    method: "post",
    data
  });
};

// 通过线路ID查询所有运营参数
const queryOperateByLineId = data => {
  return service({
    url: `${BUS_PREFIX}/operate/queryByLineId`,
    method: "get",
    data
  });
};

export { getOperateList, saveOperate, queryOperateByLineId };
/* -----------------  运营方案模块 ---------------------- */

/* -----------------  班组设置模块 ---------------------- */
// 获取班组设置列表
const getTeamList = data => {
  return service({
    url: `${BUS_PREFIX}/team/listTeam`,
    method: "post",
    data
  });
};

// 通过线路ID获取所有班组
const getTeamByLineId = data => {
  return service({
    url: `${BUS_PREFIX}/team/getTeamByLineId`,
    method: "get",
    data
  });
};

// 通过班组获取车辆信息
const getBusList = data => {
  return service({
    url: `${BUS_PREFIX}/team/getBus`,
    method: "post",
    data
  });
};

// 新增班组
const addTeam = data => {
  return service({
    url: `${BUS_PREFIX}/team/add`,
    method: "post",
    data
  });
};

// 修改班组
const updateTeam = data => {
  return service({
    url: `${BUS_PREFIX}/team/update`,
    method: "put",
    data
  });
};

// 删除 班组设置
const deleteTeam = data => {
  return service({
    url: `${BUS_PREFIX}/team/delete/${data}`,
    method: "delete"
  });
};

// 通过下路ID获取下面的机动车和非机动车司机
const getDriversByLineId = data => {
  return service({
    url: `${BUS_PREFIX}/team/getDrivers`,
    method: "get",
    data
  });
};

export {
  getTeamList,
  getTeamByLineId,
  getBusList,
  addTeam,
  updateTeam,
  deleteTeam,
  getDriversByLineId
};
/* -----------------  班组设置模块 ---------------------- */
/* -----------------  发车时刻表模块 ---------------------- */
// 获取发车时刻表列表
const querySchedulingList = data => {
  return service({
    url: `${BUS_PREFIX}/schedulingManage/startTimeTable`,
    method: "post",
    data
  });
};
// 新增排版计划
const addScheduling = data => {
  return service({
    url: `${BUS_PREFIX}/schedulingManage/addScheduling`,
    method: "post",
    data
  });
};
// 查询生成排班时方案上次的配置项
const getAutoArrangeConfig = data => {
  return service({
    url: `${BUS_PREFIX}/schedulingManage/getConfig`,
    method: "get",
    data
  });
};
// 计划管理列表
const getCheCi = data => {
  return service({
    url: `${BUS_PREFIX}/schedulingManage/queryPlanManageByQuery`,
    method: "post",
    data
  });
};
// 根据线路查询班次
const getBanCi = data => {
  return service({
    url: `${BUS_PREFIX}/schedulingManage/queryPlanBanCiByQuery`,
    method: "post",
    data
  });
};
// 查询车辆排班
const getBusPlanList = data => {
  return service({
    url: `${BUS_PREFIX}/schedulingManage/queryBusPlan`,
    method: "post",
    data
  });
};
//查询顶班可选车辆
const queryTopShiftVehicle = data => {
  return service({
    url: `${BUS_PREFIX}/schedulingManage/queryTopShiftVehicle`,
    method: "post",
    data
  });
};
//查询顶班可选驾驶员
const queryTopShiftDriver = data => {
  return service({
    url: `${BUS_PREFIX}/schedulingManage/queryTopShiftDriver`,
    method: "post",
    data
  });
};
//查询换班可选车辆
const queryShiftChangeVehicle = data => {
  return service({
    url: `${BUS_PREFIX}/schedulingManage/queryShiftChangeVehicle`,
    method: "post",
    data
  });
};
//查询换班可选驾驶员
const queryShiftChangeDriver = data => {
  return service({
    url: `${BUS_PREFIX}/schedulingManage/queryShiftChangeDriver`,
    method: "post",
    data
  });
};
//查询可换班的车辆-驾驶员
const queryShiftVehicleDriver = data => {
  return service({
    url: `${BUS_PREFIX}/schedulingManage/queryShiftVehicleDriver`,
    method: "post",
    data
  });
};
//添加班次（车次）可选车辆
const getBusByline = data => {
  return service({
    url: `${BUS_PREFIX}/schedulingManage/querySchedulingVehicles`,
    method: "post",
    data
  });
};
//添加班次（车次）可选驾驶员
const getDriverByline = data => {
  return service({
    url: `${BUS_PREFIX}/schedulingManage/querySchedulingDrivers`,
    method: "post",
    data
  });
};
//驾驶员名称 -- 模糊查询
const queryDriverByName = data => {
  return service({
    url: `/base/driver/queryDriverByName`,
    method: "get",
    data
  });
};
////驾驶员名称 -- 模糊查询---公交2.0
const queryDriverByNameNew = data => {
  return service({
    url: `/base/driver/queryDriverByNameNew`,
    method: "get",
    data
  });
};

//顶班
const topShift = data => {
  return service({
    url: `${BUS_PREFIX}/schedulingManage/topShift`,
    method: "post",
    data
  });
};
//换班
const shiftChange = data => {
  return service({
    url: `${BUS_PREFIX}/schedulingManage/shiftChange`,
    method: "post",
    data
  });
};
//增加班次
const addBanci = data => {
  return service({
    url: `${BUS_PREFIX}/schedulingManage/addBanCi`,
    method: "post",
    data
  });
};

//增加车次
const addCheci = data => {
  return service({
    url: `${BUS_PREFIX}/schedulingManage/addCheCi`,
    method: "post",
    data
  });
};
// 删除车次计划
const deleteDriverOrBus = data => {
  return service({
    url: `${BUS_PREFIX}/schedulingManage/deleteCheCiByIds`,
    method: "post",
    data
  });
};
// 查询顶班可选车次
const queryTopShiftChiCi = data => {
  return service({
    url: `${BUS_PREFIX}/schedulingManage/queryTopShiftChiCi`,
    method: "post",
    data
  });
};
export {
  querySchedulingList,
  addScheduling,
  getAutoArrangeConfig,
  getCheCi,
  getBanCi,
  queryTopShiftVehicle,
  queryTopShiftDriver,
  queryShiftVehicleDriver,
  queryShiftChangeVehicle,
  queryShiftChangeDriver,
  getBusPlanList,
  getBusByline,
  getDriverByline,
  queryDriverByName,
  queryDriverByNameNew,
  topShift,
  shiftChange,
  addBanci,
  addCheci,
  deleteDriverOrBus,
  queryTopShiftChiCi
};
/* -----------------  发车时刻表模块 ---------------------- */
/* -----------------  实时调度 ---------------------- */
// 调整时间
const updateWorkTripTime = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchMannage/updateWorkTripTime`,
    method: "post",
    data
  });
};
// 删除车次计划
const deleteCheCiByIds = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchMannage/deleteCheCiByIds`,
    method: "post",
    data
  });
};
//顶班
const topShiftDispatch = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchMannage/topShift`,
    method: "post",
    data
  });
};
// 增加班次
const addBanciDispatch = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchMannage/addBanCi`,
    method: "post",
    data
  });
};
// 查询顶班可选车次
const queryTopShiftChiCiDispatch = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchMannage/queryTopShiftChiCi`,
    method: "post",
    data
  });
};
const queryTopShiftChiCiDispatchNew = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchPlanChange/queryTopShiftChiCi`,
    method: "post",
    data
  });
};
//查询顶班可选车辆
const queryTopShiftVehicleDispatch = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchMannage/queryTopShiftVehicle`,
    method: "post",
    data
  });
};
//查询顶班可选驾驶员
const queryTopShiftDriverDispatch = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchMannage/queryTopShiftDriver`,
    method: "post",
    data
  });
};
// 增加车次
const addCheciDispatch = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchMannage/addCheCi`,
    method: "post",
    data
  });
};
// 匀点
const averageSpot = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchMannage/averageSpot`,
    method: "post",
    data
  });
};
//消息列表
const sendMessageList = data => {
  return service({
    url: `/base/sendMessage/list`,
    method: "post",
    data
  });
};
//改版后的车辆请求
const queryNewEventsList = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchPlatform/queryNewEvents`,
    method: "post",
    data
  });
};
//查询主副场即将发车的计划列表
const queryDispatchPlanList = data => {
  return service({
    url: `${BUS_PREFIX}/realDispatch/queryDispatchPlan`,
    method: "get",
    data
  });
};
//查询可选择线路--替换公交改版前的接口2021.07.06
const queryAllLineList = data => {
  return service({
    url: `${BUS_PREFIX}/realDispatch/queryAllLine`,
    method: "post",
    data
  });
};
//到离站信息列表
const queryArriveLeaveStationList = data => {
  return service({
    url: `${BUS_PREFIX}/realDispatch/queryArriveLeaveStationList`,
    method: "get",
    data
  });
};
//车辆调度统计
const queryDispatchStatisticsAll = data => {
  return service({
    url: `${BUS_PREFIX}/realDispatch/queryDispatchStatistics`,
    method: "get",
    data
  });
};
//线路详情
const queryLineDetailByCompanyIds = data => {
  return service({
    url: `${BUS_PREFIX}/realDispatch/queryLineDetailByCompanyIds`,
    method: "post",
    data
  });
};
//查询车辆实时信息
const queryBusPosition = data => {
  return service({
    url: `${BUS_PREFIX}/realDispatch/queryBusPosition`,
    method: "get",
    data
  });
};
//查询待发车列表
const queryStartPlanDetailList = data => {
  return service({
    url: `${BUS_PREFIX}/realDispatch/queryStartPlanDetailList`,
    method: "get",
    data
  });
};
//查看车辆发车计划
const queryBusPlan = data => {
  return service({
    url: `${BUS_PREFIX}/realDispatch/queryBusPlan`,
    method: "post",
    data
  });
};
//调整计划-调整时间
const updateWorkTripTimeNew = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchPlanChange/updateWorkTripTime`,
    method: "post",
    data
  });
};
//调整计划-顶班可选车辆
const queryTopShiftVehicleNew = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchPlanChange/queryTopShiftVehicle`,
    method: "post",
    data
  });
};
//调整计划-顶班可选驾驶员
const queryTopShiftDriverNew = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchPlanChange/queryTopShiftDriver`,
    method: "post",
    data
  });
};
//调整计划-顶班
const topShiftDispatchNew = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchPlanChange/topShift`,
    method: "post",
    data
  });
};
//调整计划-删除计划-可选
const queryNatureEnum = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchPlanChange/queryNatureEnum`,
    method: "get",
    data
  });
};
//调整计划-删除计划
const deleteCheCiByIdsNew = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchPlanChange/deleteCheCiByIds`,
    method: "post",
    data
  });
};

//增加车次-改版后
const addCheciDispatchNew = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchPlanChange/addCheCi`,
    method: "post",
    data
  });
};
// 增加班次-改版后
const addBanciDispatchNew = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchPlanChange/addBanCi`,
    method: "post",
    data
  });
};
//手动发车
const manualSendVehicle = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchPlatform/manualSendVehicle`,
    method: "post",
    data
  });
};
//手动修改车辆运营状态接口
const manualModifyEvent = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchPlatform/manualModifyEvent`,
    method: "post",
    data
  });
};

export {
  deleteCheCiByIds,
  updateWorkTripTime,
  topShiftDispatch,
  queryTopShiftChiCiDispatch,
  queryTopShiftChiCiDispatchNew,
  queryTopShiftVehicleDispatch,
  queryTopShiftDriverDispatch,
  addBanciDispatch,
  addCheciDispatch,
  averageSpot,
  sendMessageList,
  queryNewEventsList,
  queryDispatchPlanList,
  queryAllLineList,
  queryArriveLeaveStationList,
  queryDispatchStatisticsAll,
  queryLineDetailByCompanyIds,
  queryBusPosition,
  queryStartPlanDetailList,
  queryBusPlan,
  updateWorkTripTimeNew,
  queryTopShiftVehicleNew,
  queryTopShiftDriverNew,
  topShiftDispatchNew,
  deleteCheCiByIdsNew,
  queryNatureEnum,
  addCheciDispatchNew,
  addBanciDispatchNew,
  manualSendVehicle,
  manualModifyEvent
};
/* -----------------  实时调度 ---------------------- */
/* -----------------  模拟地图模块 ---------------------- */
// 根据id集合获取线路站点详情
const getLineAndStationsByCompanyIds = data => {
  return service({
    url: `${BUS_PREFIX}/lineDetail/queryLineDetailByCompanyIds`,
    method: "post",
    data
  });
};

// 获取计划信息
const getPlanStatisticsBycompanyId = data => {
  return service({
    url: `${BUS_PREFIX}/simulateMap/queryPlanStatisticsByCompanyIds`,
    method: "post",
    data
  });
};

// 获取车辆详细信息
const busDetailInfo = data => {
  return service({
    url: `${BUS_PREFIX}/simulateMap/querySimulateDetail`,
    method: "get",
    data
  });
};

export {
  getLineAndStationsByCompanyIds,
  getPlanStatisticsBycompanyId,
  busDetailInfo
};
/* -----------------  模拟地图模块 ---------------------- */

/* -----------------  电子地图模块 ---------------------- */
// 根据线路查询线路所有车辆
const getBusBycompanyIds = data => {
  return service({
    url: `${BUS_PREFIX}/electronicMap/queryVehicleByLineIds`,
    method: "post",
    data
  });
};

export { getBusBycompanyIds };
/* -----------------  电子地图模块 ---------------------- */

/* -----------------  线路制作模块 ---------------------- */
// 保存绘制线路的坐标点
const saveLineCoordinates = data => {
  return service({
    url: `${BUS_PREFIX}/lineMapping/save`,
    method: "post",
    data
  });
};
// 电子地图绘图信息获取
const queryLineCoordinates = data => {
  return service({
    url: `${BUS_PREFIX}/lineMapping/queryByCompanyId`,
    method: "get",
    data
  });
};
export { saveLineCoordinates, queryLineCoordinates };
/* -----------------  线路制作模块 ---------------------- */

/* -----------------  实时调度模块 ---------------------- */
// 查询关注线路列表
const getFollowLineList = data => {
  return service({
    url: `${BUS_PREFIX}/followLine/list`,
    method: "get",
    data
  });
};
// 保存关注线路
const saveFollowLine = data => {
  return service({
    url: `${BUS_PREFIX}/followLine/save`,
    method: "get",
    data
  });
};
// 删除关注线路
const deleteFollowLine = data => {
  return service({
    url: `${BUS_PREFIX}/followLine/delete`,
    method: "get",
    data
  });
};
// 查询车辆请求列表
const getEventReport = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchPlatform/queryEvents`,
    method: "post",
    data
  });
};
// 查询车辆请求列表
const getBusStatus = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchPlatform/busStatus`,
    method: "get",
    data
  });
};
// 下发行车计划
const sendPlanText = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchPlatform/sendPlanText `,
    method: "post",
    data
  });
};
// 消息下发
const sendText = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchPlatform/sendText`,
    method: "post",
    data
  });
};
// 车次计划统计
const getPlanStatisticsByLineId = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchPlatform/getPlanStatisticsByLineId`,
    method: "get",
    data
  });
};
// 查询计划下涉及的车辆
const getSamelineAllbus = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchPlatform/lineBuses`,
    method: "post",
    data
  });
};
// 查询计划下涉及的车辆
const getCheCiPlatform = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchPlatform/getCheCi`,
    method: "post",
    data
  });
};
// 待发车列表
const queryPlanDetailList = data => {
  return service({
    url: `${BUS_PREFIX}/dispatchPlatformUpgrade/queryStartPlanDetailList`,
    method: "get",
    data
  });
};
export {
  getFollowLineList,
  saveFollowLine,
  deleteFollowLine,
  getEventReport,
  getBusStatus,
  sendPlanText,
  sendText,
  getPlanStatisticsByLineId,
  getSamelineAllbus,
  getCheCiPlatform,
  queryPlanDetailList
};
/* -----------------  实时调度模块 ---------------------- */

/* -----------------  业务报表 ---------------------- */
// 查询-设备故障
const queryDeviceFaultFormBus = data => {
  return service({
    url: `${BUS_PREFIX}/busFault/queryBusFaultByPage`,
    method: "post",
    data
  });
};

export { queryDeviceFaultFormBus };

/* -----------------  业务报表 ---------------------- */

/* -----------------  调度屏到站排查 ---------------------- */
// 分页查询
const abnormalArriveQueryPageList = data => {
  return service({
    url: `/report/abnormalArrive/queryPageList`,
    method: "post",
    data
  });
};

// 模糊检索站点
const stationQueryByName = data => {
  return service({
    url: `/bus/station/queryByName`,
    method: "post",
    data
  });
};

// 详情查看
const abnormalArriveQueryById = data => {
  return service({
    url: `/report/abnormalArrive/queryById`,
    method: "get",
    data
  });
};

export {
  abnormalArriveQueryPageList,
  stationQueryByName,
  abnormalArriveQueryById
};

/* -----------------  调度屏到站排查 ---------------------- */
